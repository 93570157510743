import React from 'react';
import { Box, Grid } from '@mui/material';
import GridItem from './GridItem';
import { LibraryNameInstance,Instance } from '../types';
import styled from 'styled-components';
import { LibraryType } from '../utils/enum';

type ItemType = LibraryNameInstance | Instance;

interface ItemGridProps {
  items: ItemType[];
  libraryType: LibraryType;
}

const ItemGrid: React.FC<ItemGridProps> = ({ items, libraryType }) => {
  
  return (
    <StyledBox>
      <Grid container>
        {libraryType === LibraryType.ALL_LIBRARIES
          ? items.map((bookData) => {
              if ((bookData as LibraryNameInstance).instance && (bookData as LibraryNameInstance).instance.id) {
                const bookInstance = (bookData as LibraryNameInstance).instance;
                return (
                  <GridItem
                    key={`${bookInstance.id}-${(bookData as LibraryNameInstance).library_name}`}
                    bookData={bookInstance}
                    libraryType={libraryType}
                    libraryName={(bookData as LibraryNameInstance).library_name}
                  />
                );
              } else {
                return null;
              }
            })
          : items.map((bookData) => {
              if ((bookData as Instance).id) {
                const simpleInstance = bookData as Instance;
                return (
                  <GridItem
                    key={simpleInstance.id}
                    bookData={simpleInstance}
                    libraryType={libraryType}
                  />
                );
              } else {
                return null;
              }
            })}
      </Grid>
    </StyledBox>
  );
};

export default ItemGrid;


const StyledBox = styled(Box)`
  width: 100%
`;
