import { BottomNavigation, BottomNavigationAction, Container } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import { useEffect, useState } from 'react'
import { LibraryBooks } from '@mui/icons-material'
import Home from './Home'
import Library from './Library'
import Profile from './Profile'
import { useAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled  from 'styled-components'

export default function Landing () {
  const [value, setValue] = useState(1)
  const {authData} = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (authData == null) {
      console.log(authData)
      navigate("/login");
    }

    const storedValue = localStorage.getItem('bottomNavValue');
    if (storedValue) {
      setValue(parseInt(storedValue));
    }
  }, [])

  history.pushState(null, location.href);
   window.onpopstate = function() {
    history.go(1);
  };

  const renderScreen = () => {
    switch (value) {
      case 0:
        return <Home />
      case 1:
        return <Library />
      case 2:
        return <Profile />
      default:
        return <Home />
    }
  }
  return (
    <StyledContainer disableGutters>
      {renderScreen()}
    <StyledBottomNavigation
      showLabels
      value={value}
      onChange={(_event, newValue) => {
        setValue(newValue);
        localStorage.setItem('bottomNavValue', newValue.toString());
      }}>
      <BottomNavigationAction label={t('Home')} icon={<HomeIcon />} />
      <BottomNavigationAction label={t('Library')} icon={<LibraryBooks />} />
      <BottomNavigationAction label={t('Profile')} icon={<PersonIcon />} />
    </StyledBottomNavigation>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  min-height: 100vh;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;
