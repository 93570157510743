import { UserDetails } from "../types";
import { CREATE_USER_API_ENDPOINT, USERS_API_ENDPOINT } from "../utils/api";
import { authenticatedFetch } from "./utils";
import { AuthenticatedApiHook } from '../types';

export async function getUsers(
  onlyAssociates: boolean,
  offset: number = 0,
  limit: number = 10
): Promise<UserDetails[]> {
  let url = `${USERS_API_ENDPOINT}?offset=${offset}&limit=${limit}`;
  if (onlyAssociates) {
    url += "&only_associate=true";
  }
  const response = await authenticatedFetch(url);
  if (response.ok) {
    const data: UserDetails[] = await response.json();
    return data;
  } else {
    console.error("Failed to get items");
    return [];
  }
}

export async function createUser(
  authenticatedApiHook: AuthenticatedApiHook,
  userData: UserDetails,
) {
  const response = await authenticatedApiHook(CREATE_USER_API_ENDPOINT,"POST",undefined,userData);
  if (response) {
    const data = await response.json();
    return data;
  }
  return null;
}