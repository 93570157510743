import React, { useState } from 'react'

const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return {
    open,
    error,
    setError,
    handleClick,
    handleClose,
  };
};

export default useSnackbar;