import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useItems } from '../hooks/useItems';
import logo from '../assets/img/toplogo.png';
import cover from '../assets/img/homesearch.png';
import noitem from '../assets/img/noitem.png';
import HomeHeader from '../components/HomeHeader';
import HomeCover from '../components/HomeCover';
import NoItems from '../components/NoItems';
import FabButton from '../components/FabButton';
import CataloguedItems from '../components/CataloguedItems';
import ItemGrid from '../components/ItemGrid';
import ItemList from '../components/ItemList';
import LoadMoreButton from '../components/LoadMoreButton';
import styled from 'styled-components';
import { useSearchParams } from "react-router-dom";
import LibraryTabs from '../components/LibraryTabs';
import { Instance, LibraryNameInstance } from '../types';
import { LibraryType } from '../utils/enum';

const Home: React.FC = () => {
  let [searchParams, _setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<string>(localStorage.getItem('itemType') || '');
  const [search, setSearch] = useState<string>(searchParams.get("search") || '');
  const [offset, setOffset] = useState<number>(0);
  const [libraryType, setLibraryType] = useState<LibraryType>(LibraryType.MY_LIBRARY);
  const { t } = useTranslation();
  const [isGridView, setIsGridView] = useState(true);
  const { items, totalBookCount, loading, totalInstanceCount } = useItems(search, offset,libraryType, filter);
  const [key, setKey] = useState(0);
  const [bookData, setBookData] = useState<(LibraryNameInstance| Instance )[]>([]);

  useEffect(() => {
    if (items.length > 0) {
      setKey((prevKey) => prevKey + 1);
    }
    setBookData(items)
  }, [items]); 


  useEffect(() => {
    if (search !== "") setOffset(0);
    localStorage.setItem('itemType', filter || '');
  }, [search, filter]);

  useEffect(()=>{
    setOffset(0);
  },[libraryType]);


  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('itemType');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
 

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuItemClick = (option: string) => {
    setFilter(option);
    handleMenuClose();
  };
  const handleToggleView = () => {
    setIsGridView(prevIsGridView => !prevIsGridView);
  };

  const onLoadMore = () => setOffset(prevOffset => prevOffset + 10);
  return (
    <StyledContainer disableGutters>
      <HomeHeader t={t} logo={logo} />
      <HomeCover cover={cover} search={search} setSearch={setSearch} t={t} />
      <LibraryTabs setValue={setLibraryType} value={libraryType}/>
      <FabButton />
      <CataloguedItems
        t={t}
        items={bookData}
        totalBookCount={totalBookCount}
        loading={loading}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleMenuOpen={handleMenuOpen}
        handleMenuClose={handleMenuClose}
        handleMenuItemClick={handleMenuItemClick}
        filter={filter}
        setFilter={setFilter}
        isGridView={isGridView}
        handleToggleView={handleToggleView}
        libraryType={libraryType}
      />
      <NoItems t={t} items={bookData} loading={loading} search={search} noitem={noitem} filter={filter} />
      {isGridView ? <ItemGrid key={key} items={bookData} libraryType={libraryType}/> : <ItemList key={key} items={bookData} libraryType={libraryType}/>} 
      <LoadMoreButton items={bookData} totalInstanceCount={totalInstanceCount} totalBookCount={totalBookCount} loading={loading} onLoadMore={onLoadMore} search={search} />
    </StyledContainer>
  );
};

export default Home;
const StyledContainer = styled(Container)`
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 64px;
`;
