import * as Sentry from "@sentry/react";

type Body<T = unknown> = {
  [key: string]: T;
};

function getToken() {
  const authData: string | null = localStorage.getItem("authData");
  if (authData!==null && authData!=="null") {
    return JSON.parse(authData).access;
  }
}

// TODO: keep return type consistent
export async function authenticatedFetch(
  url: string,
  body: Body | null = null,
  method: string = "GET",
  contentType: string = "application/json"
): Promise<Response> {
  const headers = new Headers();
  const token = getToken();

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  if (contentType == "application/json") {
    headers.append("Content-Type", contentType);
  }

  let payload;
  if (contentType === "application/json") {
    payload = body ? JSON.stringify(body) : null;
  } else if (contentType === "multipart/form-data") {
    payload = new FormData();
    if (body) {
      for (const key in body) {
        const value = body[key];
        if (!value) continue;
        if (value instanceof File) {
          payload.set(key, value);
        } else if (typeof value !== "string") {
          payload.set(key, value.toString());
        } else {
          payload.set(key, value);
        }
      }
    }
  }

  const options = {
    method: method,
    headers: headers,
    body: payload,
  };
  try{
    const response = await fetch(url, options);
    if (!response.ok) {
      if (response.status == 401) {
        alert("Unauthorized. Please logout and login again.");
        throw new Error("Unauthorized. Please logout and login again.");
      } else if (response.status == 400) {
        const error = await response.json();
        let errorMessage;
        if (error.non_field_errors) {
          errorMessage = error.non_field_errors[0]
        } else if (error.detail) {
          errorMessage = error.detail
        } else {
          let errorString = "";
          for (const key in error) {
            errorString += `${key}: ${error[key]}\n`;
          }
          // This need to be refactored
          errorMessage = errorString;
        }
        throw new Error(errorMessage);
      } else if (response.status == 500) {
        throw new Error(response.statusText);
      } else {
        throw new Error(response.statusText);
      }
    }
    return response;
  }
  catch(error){
    Sentry.captureException(error);
    return Promise.reject(null);
  }
}