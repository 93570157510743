import React, { useEffect } from "react";
import {
  Button,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { getItemByLibraryAndAccessionCode } from "../api/items";
import { Scanner } from '@yudiel/react-qr-scanner';
import { useSnackbar } from "../components/SnackbarProvider";

const SearchScannerScreen: React.FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const onDecode = async (result: string) => {
    const splits = result.split("/");
    navigate(`/landing?search=${splits[splits.length - 1]}`)
    // if (splits.length > 5) {
    //   const accessionCode = splits[splits.length - 1];
    //   const uniqueLibraryId = splits[splits.length - 2];
    //   try {
    //     const itemData = await getItemByLibraryAndAccessionCode(uniqueLibraryId, accessionCode);
    //     console.log(itemData);
    //     navigate(`/view-instance/${itemData.instance}`);
    //   }
    //   catch (error) {
    //     alert("Item not found");
    //     navigate(`/landing?search=${splits[splits.length - 1]}`)
    //   }
    // }
  }

  useEffect(() => {
    const checkCameraPermissions = async () => {

      try {
        const permissionStatus = await navigator.permissions.query({ name: "camera" as PermissionName });
        if (permissionStatus.state !== "granted" && permissionStatus.state !== "prompt") {
          showMessage("Camera permission is denied. Please allow camera access in your browser settings.", "error", 4000);
        }
      } catch (error) {
        showMessage("An error occurred while checking camera permissions.", "error", 4000);
      }
    };

    checkCameraPermissions();
  }, []);

  return (
    <Box>
      <Scanner onScan={(result) => onDecode(result[0].rawValue)}
        formats={["ean_13", "qr_code"]} scanDelay={100} components={{ zoom: true, torch: true }} />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Button onClick={() => { navigate(-1) }}>Close Scanner</Button>
      </Box>
    </Box>
  );
};

export default SearchScannerScreen;
