import { useSnackbar } from '../components/SnackbarProvider';
import { useCallback } from 'react';
import ky, { HTTPError } from 'ky';
import { useNavigate,NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';


type Body<T = unknown> = {
  [key: string]: T;
};

function getToken(): string | null {
  const authData: string | null = localStorage.getItem('authData');
  if (authData !== null && authData !== 'null') {
    return JSON.parse(authData).access;
  }
  return null;
}

async function handleResponse(
  response: Response,
  t: TFunction<"translation", undefined>,
  showMessage: (
    message: string,
    variant: 'success' | 'error' | 'warning' | 'info',
    duration: number,
  ) => void,
  navigate: NavigateFunction 
): Promise<Response | null> {
  if (!response.ok) {
    let errorMessage = 'An error occurred';
    switch (response.status) {
      case 401:
        errorMessage = 'Unauthorized. Please logout and login again.';
        localStorage.removeItem('authData'); 
        navigate("/login");
        break;

      case 400:
      {
        const error = await response.json();
    
        if (Array.isArray(error) && error.length > 0) {
            errorMessage = error.join('\n');  
        } else if (error.non_field_errors) {
            errorMessage = error.non_field_errors[0];
        } else if (error.detail) {
            errorMessage = error.detail;
        } else {
            errorMessage = Object.entries(error)
                .map(([key, value]) => `${key}: ${value}`)
                .join('\n');
        }
        break;
      }
      case 404:
      {
        const error_404 = await response.json();
        if(error_404.error)
          errorMessage = error_404.error
        else
          errorMessage = 'The requested resource was not found.';
        break;
      }
      case 500:
      {
        const serverError = await response.json();
        errorMessage = serverError.error || response.statusText;
        break;
      }

      default:
      {
        const defaultError = await response.json();
        errorMessage = defaultError.error || response.statusText;
      }
    }

    showMessage(t(errorMessage), 'error', 4000);
    return null;
  }

  return response;
}

async function performFetch(
  url: string,
  options: RequestInit,
  t: TFunction<"translation", undefined>,
  showMessage: (
    message: string,
    variant: 'success' | 'error' | 'warning' | 'info',
    duration: number,
  ) => void,
  navigate: NavigateFunction ,
): Promise<Response | null> {
  try {
    let response: Response | undefined;

    try {
      response = await ky(url, options);
    } catch (error: unknown) {
      // Check if the error is an instance of HTTPError
      if (error instanceof HTTPError) {
        response = error.response;
      } else {
        throw error; // Re-throw if it's not the expected error type
      }
    }

    // Handle the response (even if it's an error status)
    return response ? await handleResponse(response, t, showMessage,navigate) : null;
  } catch (error: unknown) {
    if (error instanceof TypeError) {
      showMessage('Network error. Please check your internet connection.', 'error', 4000);
    } else if (error instanceof Error) {
      showMessage(error.message, 'error', 4000);
    } else {
      showMessage('An unknown error occurred.', 'error', 4000);
    }
    return null;
  }
}

export function useAuthenticatedRequest() {
  const { showMessage } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authenticatedRequest = useCallback(
    async (
      url: string,
      method: string = 'GET',
      contentType: string = 'application/json',
      body: Body | null = null,
    ): Promise<Response | null> => {
      const token = getToken();
      const headers: Record<string, string> = {};

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      let options: any = {
        method,
        headers,
        timeout: method === "GET" ? 10000 : 20000,
      };
      if (body) {
        if (contentType === 'application/json') {
          options.json = body;
        } else if (contentType === 'multipart/form-data') {
          const formData = new FormData();
          
          for (const key in body) {
            const value = body[key];
            if (value === null) {
              formData.append(key, 'null');
            }
            else if (value !== undefined) {
              formData.append(key, value instanceof File ? value : value.toString());
            }
          }
          options.body = formData;
        }
      }

      return await performFetch(url, options, t, showMessage,navigate);
    },
    [showMessage],
  );

  return authenticatedRequest;
}
