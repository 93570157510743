import { Box, Button, Container, IconButton, Typography,InputAdornment,FormControl, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ItemInstance } from "../types";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import styled from '@emotion/styled';
import { useTranslation } from "react-i18next";
import InstanceItems from "../components/InstanceItems";
import { getItems } from "../api/items";
import { useSearchParams } from "react-router-dom";
import { SearchOutlined as SearchOutlinedIcon } from "@mui/icons-material";
import NoItems from "../components/NoItems"

const CataloguedItems: React.FC = () => {
    const navigate = useNavigate();
    const [myItems, setMyItems] = useState<ItemInstance[]>([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user_id = queryParams.get('user_id');
    const { isArchived } = location.state || {  isArchived: false };
    const [loading, setLoading] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [totalInstanceCount, setTotalInstanceCount] = useState<number>(0);
    const { t } = useTranslation();
    let [searchParams, _setSearchParams] = useSearchParams();
    const [search, setSearch] = useState<string>(searchParams.get("search") || '');
    const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);
    useEffect(() => {
        if (search !== "") {
            setOffset(0);
        }
    }, [search]);

    useEffect(() => {
        const fetchLibraryDetails = async () => {
            setLoading(true);
            try {
                const archivedItems = isArchived;
                let onlyMyItems = false;

                if (isArchived) {
                    onlyMyItems = false;
                } else if (user_id !== undefined) {
                    onlyMyItems = false;
                } else {
                    onlyMyItems = true;
                }

                const { count, items } = await getItems(search, "", onlyMyItems, offset, 10, archivedItems, true, user_id);
                if (offset === 0) {
                    setMyItems(items); // Replace items when offset is 0
                } else {
                    setMyItems(prevItems => [...prevItems, ...items]); // Append items otherwise
                }
                setTotalInstanceCount(count);

            } catch (error) {
                console.error('Error loading items:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLibraryDetails();
    }, [offset, search]); 


    const handleFetchDataAfterUnArchive = async()=>{
        setLoading(true);
        try {
            const {count, items} = await getItems("","",false,offset,10,true,true,user_id);
            setMyItems(items);
            setTotalInstanceCount(count);
        } catch (error) {
            console.error('Error loading items:', error);
        } finally {
            setLoading(false);
        }
    }

    const onLoadMore = () => {
        if (myItems.length < totalInstanceCount) {
            setOffset(offset + 10);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        // Check if input is of only numbers
        if (/^\d*$/.test(value)) {
            setIsInvalidInput(false);
            setSearch(value);
        } else {
            setIsInvalidInput(true);  // Show error if invalid
        }
    };

    return (
        <StyledContainer disableGutters>
            <StyledHeader>
                <StyledIconButton onClick={() => navigate("/landing")}>
                    <KeyboardArrowLeft />
                </StyledIconButton>
                <StyledTypography>
                    {isArchived ? t('Archived Items'): t('Books Catalogued')}
                </StyledTypography>
                <Spacer />
            </StyledHeader>
            {isArchived && 
            <>
             <StyledFormControl variant="outlined">
                    <StyledOutlinedInput
                    value={search}
                    onChange={handleSearchChange}
                    id="search"
                    startAdornment={
                        <InputAdornment position="start">
                        <SearchOutlinedIcon />
                        </InputAdornment>
                    }
                    
                    placeholder={t("Accession Code")}
                    inputProps={{ style: { padding: "14px 16px", textAlign: "left" } }}
                    />
                    {isInvalidInput && (  
                        <ErrorText>{t("Please enter numbers only (Accession Code)")}</ErrorText>
                    )}
                </StyledFormControl>
               {/* Check if search result is empty */}
                {myItems.length === 0 && search.trim() !== "" && (
                <NoItems 
                    t={t} 
                    items={[]} 
                    loading={loading} 
                    search={search} 
                    noitem={'NOIMG'} 
                    filter={'null'} 
                />
               
                )}
            </>
            }

            <StyledItemsContainer>
                {myItems.map(items => (
                    <InstanceItems key={items.id} itemData={items} onUnArchieve={handleFetchDataAfterUnArchive} isArchived={isArchived} userId={user_id}/> 
                ))}
                </StyledItemsContainer>

            {myItems.length < totalInstanceCount && (
                <StyledLoadMore>
                    <Button onClick={onLoadMore} disabled={loading}>
                        {loading ? `${t('Loading')}...` : t('Load More')}
                    </Button>
                </StyledLoadMore>
            )}
        </StyledContainer>
    );
};

export default CataloguedItems;

const StyledContainer = styled(Container)`
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 8px;
`;

const StyledHeader = styled(Box)`
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    background-color: white;
    width: 100vw;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const StyledItemsContainer = styled(Box)`
    padding: 16px;
`;

const StyledTypography = styled(Typography)`
    padding: 16px;
    font-weight: 500;
`;

const StyledLoadMore = styled(Box)`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const StyledIconButton = styled(IconButton)`
    padding: 0
`

const Spacer = styled(Box)`
  width: 30px;
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin: 16px 0;
    width: -webkit-fill-available;
    padding: 0 16px;
  }
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  && {
    background: white;
    border-radius: 11px;
    font-size: 14px;
    font-family: "Noto Sans Variable";
  }
`;

const ErrorText = styled(Typography)` 
    color: red;
    font-size: 12px;
    margin-top: 4px;
`;