import { Box, Modal, Typography } from '@mui/material';

import React from 'react';
import kmqr from '../assets/img/KMQR.png';
import { t } from 'i18next';

interface KnowMoreModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const KnowMoreModal: React.FC<KnowMoreModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{ position: 'fixed', bottom: 0, left: '50%', transform: 'translate(-50%, 0)', width: '100%', bgcolor: '#FFFFFF', boxShadow: 24, p: 2, borderTopLeftRadius: '24px', borderTopRightRadius: '24px' }}>
        <Typography fontSize={'18px'} color={'#908056'} fontWeight={600} py={1}>{t('Know more about QR Code')}</Typography>
        <Typography fontSize={'16px'} color={'#494949'} fontWeight={600} pb={1}>{t('Accession Code')}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', pb: 2, justifyContent: 'space-between', flex: 1 }}>
          <img src={kmqr} width={'154px'} height={'154px'} style={{ flex: 0.5 }} />
          <Box px={2} flex={0.5} display={'flex'} flexDirection={'column'}>
            <Typography fontSize={'14px'} pb={1}>{t('Information stored')} -</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>1.{t('Shelf Numbers')}</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>2.{t('Accession Code')}</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>3.{t('Title, Author, Volume')}</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>4.{t('Front Cover of Item')}</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>5.{t('Publisher Details')}</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>6.{t('ISBN')}</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>7.{t('Resource Condition')}</Typography>
          </Box>
        </Box>
        <Typography fontSize={'16px'} color={'#494949'} fontWeight={600} pb={1}>{t('Shelf QR Code')}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', pb: 2, justifyContent: 'space-between', flex: 1 }}>
          <img src={kmqr} width={'154px'} height={'154px'} style={{ flex: 0.5 }} />
          <Box px={2} flex={0.5} display={'flex'} flexDirection={'column'}>
            <Typography fontSize={'14px'} pb={1}>{t('Information stored')} -</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>1.{t('Items in particular shelf')}</Typography>
            <Typography fontSize={'12px'} color={'#696969'}>2.{t('Shelf Number')}</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default KnowMoreModal;
