import * as React from 'react';
import { Box, Typography, Divider, OutlinedInput, FormControl, InputLabel, Button } from '@mui/material';
import { ItemInstance, Instance } from '../types';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { setItemArchived,deleteArchivedItem } from '../api/items';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";

interface InstanceItemsProps {
  itemData: ItemInstance;
  onUnArchieve: () => void;
  isArchived: boolean;
  userId: string | null;
}

const InstanceItems: React.FC<InstanceItemsProps> = ({ itemData, onUnArchieve, isArchived,userId }) => {
  const navigate = useNavigate();
  const itemId = itemData.id ? itemData.id : null
  const instance = itemData.instance as Instance;  // Type assertion
  const name = instance.title || 'Unknown Book';
  const authorText = instance.author ? `${instance.author}` : 'Unknown Author';
  const yearText = instance.published_year ? `${instance.published_year}` : '';
  const onlyMyItems = !isArchived && (userId === undefined || userId === null);
  const { t } = useTranslation();
  const handleArchive = async () => {
    if (itemData.id) {
      if (window.confirm(t("Are you sure you want to un-archive this item?"))) {
        await setItemArchived(itemData.id.toString(), false);
        alert(t("Item Un-Archived"));
        onUnArchieve();
      }
    }
  }

  const handleDelete = async () => {
    if (itemData.id) {
      const userConfirmed = window.confirm(t("Are you sure you want to delete this item?"));
      if (userConfirmed) {
        const deleteResponse = await deleteArchivedItem(itemData.id.toString());
        
        if (deleteResponse.success) {
          alert("Item Deleted");
          onUnArchieve();
        } else {
          alert(t("Failed to delete the item. Please try again."));
        }
      }
    }
  };


  const handleNavigate = () => {
    if (instance.id && itemId) {
        let url = `/view-instance/${instance.id}?itemId=${itemId}&archived=${isArchived}&onlyMyItems=${onlyMyItems}`;
        if (userId) {
            url += `&userId=${userId}`;
        }
        navigate(url);
    }
};

  return (
    <Container>
      <StyledContainer onClick={handleNavigate}>
        <StyledImage src={instance?.front_cover} alt={name} />
        <StyledInfo>
          <StyledTitle>{name}</StyledTitle>
          <StyledAuthor>{authorText}{yearText ? `, ${yearText}` : ''}</StyledAuthor>
        </StyledInfo>
        {isArchived && 
        <StyledButtonContainer>
          <StyledButton
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from propagating to the container
              handleArchive();
            }}
          >
            {t('Un-Archive')}
          </StyledButton>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            sx={{
              marginLeft: '8px',
              color: '#F95656',
              cursor: 'pointer',
              display: 'flex', 
              alignItems: 'center',
            }}
          >
            <DeleteIcon />
          </Box>
        </StyledButtonContainer>
        }

      </StyledContainer>
      <Divider sx={{ my: 1 }} />
      <StyledDetailsContainer>
        <StyledDetail>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel sx={{ color: '#242424', fontSize: "14px" }}>{t('Shelf QR Code')}</InputLabel>
            <OutlinedInput
              value={itemData.temporary_shelf_number || 'N/A'}
              readOnly
              disabled
              label="Shelf QR Code"
              sx={{ background: "white", borderRadius: "12px" }}
            />
          </FormControl>
        </StyledDetail>
        <StyledDetail>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel sx={{ color: '#242424' }}>{t('Accession Code')}</InputLabel>
            <OutlinedInput
              value={itemData.accession_code || 'N/A'}
              readOnly
              disabled
              label="Accession Code"
              sx={{ background: "white", borderRadius: "12px" }}
            />
          </FormControl>
        </StyledDetail>
      </StyledDetailsContainer>
    </Container>
  );
};

export default InstanceItems;

const Container = styled(Box)`
  margin-bottom: 30px;
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items vertically */
  cursor: pointer;
  padding: 8px;
`;

const StyledImage = styled.img`
  height: 75px;
  width: 56.46px;
`;

const StyledInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center items vertically */
  flex: 1; /* Allow to take up remaining space */
  padding-left: 16px;
`;

const StyledTitle = styled(Typography)`
  font-size: 16px;
  padding-bottom: 6px;
`;

const StyledAuthor = styled(Typography)`
  font-size: 14px;
  color: #908056;
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  align-items: center; /* Center button vertically */
  margin-left: 16px; /* Space between text and button */
`;

const StyledButton = styled(Button)`
  width: 120px;
  height: 28px;
  border-radius: 26px;
  border: 1px solid #FFA100;
  padding: 4px 8px;
  gap: 8px;
  color: #FFA100; 
  text-transform: none;
  background-color: transparent;
  &:hover {
    background-color: #FFF8E1;
  }
`;

const StyledDetailsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledDetail = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0;
  }
`;
