import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from "react-i18next";
import { LibraryType } from '../utils/enum';

interface LibraryTabsProps {
    setValue: (value: LibraryType) => void;
    value: LibraryType; 
}


const LibraryTabs: React.FC<LibraryTabsProps> = ({ setValue, value }) => {

    const handleChange = (_event: React.SyntheticEvent, newValue: LibraryType) => {
        setValue(newValue);
    };
    const { t } = useTranslation();


    return (
        <Box sx={{ width: '100%', background: "#FFFFFF" }}>
        <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            centered
            sx={{
                '& .MuiTab-root': {
                    color: '#949494', 
                    '&.Mui-selected': {
                        color: '#FFA100',
                    },
                    mx:2, 
                    textTransform: 'none',
                },
                '& .MuiTabs-indicator': {
                    backgroundColor: '#FFA100',
                },
            }}
        >
        <Tab value={LibraryType.MY_LIBRARY} label={t("My Library")} />
        <Tab value={LibraryType.ALL_LIBRARIES} label={t("All Libraries")} />
        </Tabs>
    </Box>
    
    );



}


export default LibraryTabs;