import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import { Container, Box, IconButton, Typography, FormControl, InputAdornment, InputLabel, OutlinedInput, Button } from '@mui/material'
import React, { useState } from 'react'
import VisibilityOff  from '@mui/icons-material/VisibilityOff';
import Visibility  from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CHANGE_PASSWORD_API_ENDPOINT } from '../utils/api';
import { authenticatedFetch } from '../api/utils';
import { useSnackbar } from '../components/SnackbarProvider';

export default function ChangePassword() {
  
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
  };
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

const handleConfirm = async () => {
  if (newPassword !== confirmPassword) {
    showMessage('Passwords do not match', "error", 4000);
    return;
  }

  try {
    const response = await authenticatedFetch(CHANGE_PASSWORD_API_ENDPOINT, {
      old_password: oldPassword,
      new_password1: newPassword,
      new_password2: confirmPassword,
    }, 'POST');

    if (!response || !response.ok) {
      showMessage(response, "error", 4000);
      return;
    }

    const data = await response.json();

    if (response.status === 200) {
      showMessage(data?.detail || 'Password changed successfully!', 'success', 2000);
      navigate('/landing');
    }

  } catch (error) {
    showMessage('Failed to change password. Try again later.', "error", 4000);
  }
};


  return (
    <Container disableGutters sx={{height: '100vh', overflowY: 'scroll', pb: 8}}>
    {/* Header Box */}
    <Box display={'flex'} flexDirection={'row'} p={'16px'} bgcolor={'white'} width={'100vw'} maxWidth={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <IconButton onClick={() => navigate('/account-settings')}>
              <KeyboardArrowLeft />
          </IconButton>
        <Typography fontSize={'16px'} fontWeight={500}>
            {t('Change Password')}
        </Typography>
        <Box width={'30px'}></Box>
    </Box>

    <Box p={'16px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <InputLabel sx={{ fontWeight: 400, color: '#949494' }} htmlFor="outlined-adornment-oldpassword">{t('Old Password')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-oldpassword"
          sx={{background: 'white', borderRadius: '12px'}}
          type={showOldPassword ? 'text' : 'password'}
          value={oldPassword} 
          onChange={(e) => setOldPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowOldPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
                color='info'
              >
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Old Password"
        />
      </FormControl>
      <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <InputLabel sx={{ fontWeight: 400, color: '#949494' }} htmlFor="outlined-adornment-newpassword">{t('New Password')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-newpassword"
          sx={{background: 'white', borderRadius: '12px'}}
          type={showPassword1 ? 'text' : 'password'}
          value={newPassword} 
          onChange={(e) => setNewPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword1}
                onMouseDown={handleMouseDownPassword}
                edge='end'
                color='info'
              >
                {showPassword1 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="New Password"
        />
      </FormControl>

      <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <InputLabel sx={{ fontWeight: 400, color: '#949494' }} htmlFor="outlined-adornment-confirmpassword">{t('Confirm Password')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-confirmpassword"
          sx={{background: 'white', borderRadius: '12px'}}
          type={showPassword2 ? 'text' : 'password'}
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword2}
                onMouseDown={handleMouseDownPassword}
                edge='end'
                color='info'
              >
                {showPassword2 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm Password"
        />
      </FormControl>

    </Box>

    <Box position={'absolute'} bottom={'5vh'} left={'50%'} sx={{transform: 'translateX(-50%)', width: '100%'}} px={'16px'}>
      <Button onClick={handleConfirm} variant="contained" sx={{textTransform: 'none', py: 2, borderRadius: 150, width: '100%'}}>
        <Typography fontSize={16} fontWeight={400} color='white' letterSpacing={'0.96px'}>
          {t('Reset Password')}
        </Typography>
      </Button>
    </Box>  

    </Container>
  )
}
