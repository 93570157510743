import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AuthorOptions, CollectionOptions, ItemData } from "../types";
import React, { useEffect, useState } from "react";
import {
  getCollections,
  getInstance,
  getOrCreateCollection,
  searchPersons,
  updateInstance,
} from "../api/items";
import { useNavigate, useParams , useLocation} from "react-router-dom";

import ImageUpload from "../components/ImageUpload";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import QRCodeScanner from "../components/QRCodeScanner";
// import { useSnackbar } from "../components/SnackbarProvider";
import { useTranslation } from "react-i18next";

export default function EditItem() {
  const { instanceId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const allLibrary = queryParams.get('allLibrary') === 'true';
  const [qrEnabled, setQREnabled] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  // const { showMessage } = useSnackbar();
  const [itemData, setItemData] = React.useState<ItemData>({
    id: null,
    isbn: "",
    title: "",
    author: "",
    author_name: "",
    edition: "",
    front_cover: "",
    back_cover: "",
    random_page: "",
    condition: "Good",
    notes: "",
    publisher: "",
    collection: "",
    published_year: "",
    place_of_publication: "",
    instance: null,
    accession_code: "",
    temporary_shelf_number: "",
    page_count: "",
    is_multi_volume: "false",
    review: false,
    restricted_access: false,
    item_type: "BOOK",
    front_cover_full: "",
    scanning_status: "",
  });
  const [originalItemData, setOriginalItemData] = React.useState<ItemData>();
  const [imageEncodedString, setImageEncodedString] = useState({
    front_cover: "",
    back_cover: "",
    random_page: "",
  });
  const [authorOptions, setAuthorOptions] = useState<AuthorOptions[]>([]);
  const [instanceOptions, setInstanceOptions] = useState<ItemData[]>([]);
  const [collectionOptions, setCollectionOptions] = useState<
    CollectionOptions[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");
    const [isSaveLoading, setIsSaveLoading] = useState<boolean | undefined>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      if (instanceId) {
        const instanceData = await getInstance(instanceId);
        setImageEncodedString(instanceData);
        const instanceWithoutImage = { ...instanceData };
        delete instanceWithoutImage.front_cover;
        delete instanceWithoutImage.back_cover;
        delete instanceWithoutImage.random_page;
        if (instanceWithoutImage.collection_name) {
          instanceWithoutImage.is_multi_volume = "true";
        }
        setItemData(instanceWithoutImage);
        setOriginalItemData(instanceWithoutImage);
      }
    };

    loadData();
  }, [instanceId]);

  const handleChange = (name: string, value: any) => {
    if (name === "isbn") {
      if (!isNaN(value) && !isNaN(parseInt(value))) {
        setItemData({ ...itemData, [name]: value });
      }
      else {
        setErrorMessage('Invalid ISBN');
        setOpen(true);
        setItemData({ ...itemData, [name]: "" });
        return;
      }
    }
    if (name === "page_count") {
      if (!isNaN(value) && !isNaN(parseInt(value))) {
        setItemData({ ...itemData, [name]: value });
      }
      else {
        setErrorMessage('Invalid Page Count');
        setOpen(true);
        setItemData({ ...itemData, [name]: "" });
        return;
      }
    }
    if (name === "published_year") {
      if (!isNaN(value) && !isNaN(parseInt(value))) {
        setItemData({ ...itemData, [name]: value });
      }
      else {
        setErrorMessage('Invalid Year');
        setOpen(true);
        setItemData({ ...itemData, [name]: "" });
        return;
      }
    }
    setItemData({ ...itemData, [name]: value });
  };

  // TODO: @lakshay why are we doing validations at interval?
  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => {
      const currentYear = new Date().getFullYear();
      const year = parseInt(itemData.published_year);

      if (year < 800 || year > currentYear) {
        console.log("year", year, currentYear)
        setErrorMessage('Year is invalid.');
        setOpen(true);
        setItemData(prevData => ({ ...prevData, ["published_year"]: "" }));
      }
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };

  }, [itemData.published_year]);

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleImageChange = (file: File | null, field: string) => {
    console.log(field);
    if (!file) {
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        if (typeof event.target?.result === "string") {
          const mimeType = event.target.result.split(",")[0].split(":")[1];
          // @ts-ignore
          setImageEncodedString((prevState) => ({
            ...prevState,
            [field]: `data:${mimeType};base64,${
              // @ts-ignore
              event?.target?.result?.split(",")[1]
              }`,
          }));
          setItemData((prevState) => ({ ...prevState, [field]: file }));
        }
      };
    }
  };

  const fetchAuthors = async (query: string) => {
    if (!query) return;
    const authors = await searchPersons(query);
    if (authors) {
      const _authorOptions = authors.map((author: any) => ({
        id: author.id,
        bdrc_id: author.bdrc_id,
        name: author.name,
        display_name: author.birth ? `${author.name} (${author.birth})` : author.name,
      }));
      setAuthorOptions(_authorOptions);
    }
  };

  const searchCollections = async (query: string) => {
    if (!query) return;
    const collections = await getCollections(query);
    if (collections) {
      setCollectionOptions(collections);
    }
  };

  const handleSubmit = async () => {
    const modifiedItemData = { ...itemData };
    
    const bdrcConnectedFields = ["title", ];
    if (itemData.bdrc_id) {
      for (const field of bdrcConnectedFields) {
        // @ts-ignore
        if (originalItemData[field] !== itemData[field]) {
          if (!confirm(`${t("You have changed")} ${field} ${t("that is connected to BDRC. Are you sure you want to proceed?")}`)) {
            return;
          }
        }
      }

      if (itemData.author_name !== originalItemData.author) {
        if (!confirm(`${t("You have changed")} ${t("author")} ${t("that is connected to BDRC. Are you sure you want to proceed?")}`)) {
          return;
        }
      }
    }

    // For BDRC matches, if itemData.front_cover is a string, download the image and convert it to a file
     setIsSaveLoading(true)
    if (typeof itemData.front_cover === "string") {
      const response = await fetch(itemData.front_cover);
      const blob = await response.blob();
      const file = new File([blob], "front_cover.jpg", { type: "image/jpeg" });
      modifiedItemData.front_cover = file;
      modifiedItemData.id = instanceId;
    }
    // If no collection matched and a collection name is provided, create it.
    if (!modifiedItemData.collection && !!modifiedItemData.collection_name) {
      const collection = await getOrCreateCollection(modifiedItemData.collection_name)
      modifiedItemData.collection = collection.id;
    }
    const item = await updateInstance(modifiedItemData);
    setIsSaveLoading(false)
    if (!item) {
      throw new Error("Creation failed");
    }

    alert("Item updated!");
    navigate(`/view-instance/${instanceId}?allLibrary=${allLibrary}`);
  };

  const isSaveDisabled = () => {
    if (!itemData.item_type) {
      return "item_type";
    }
    if (!itemData.title) {
      return "title";
    }
    if (itemData.is_multi_volume === "true" && !itemData.collection_name) {
      return "collection-search"
    }
    return null;
  };

  const handleFocus = () => {
    let _fieldName = isSaveDisabled()
    if (_fieldName) {
      if (_fieldName === "front_cover" || _fieldName === "random_page") {
        _fieldName = "upload_" + _fieldName
      }
      const element = document.getElementsByName(_fieldName)[0];
      if (element) {
        element.focus();

        element.addEventListener('mouseenter', () => {
          element.classList.add('hover-effect');
        });

        element.addEventListener('mouseleave', () => {
          element.classList.remove(':hover-effect');
        });
        return;
      }
      const _element = document.getElementById(_fieldName);
      if (_element) {
        _element.focus();
        if (_fieldName.startsWith("upload_")) {
          _element.style.border = '1px solid #6D2312';
          _element.style.backgroundColor = "rgba(109, 35, 18, 0.04)";
          setTimeout(() => {
            _element.style.border = '1px solid #DFDFDF';
            _element.style.backgroundColor = "white";
          }, 2000)
        }
        return;
      }
    }
  }

  return (
    <Container
      disableGutters
      sx={{ height: "100vh", overflowY: "scroll", pb: 8 }}
    >
      {/* Header Box */}
      <Box
        display={"flex"}
        flexDirection={"row"}
        p={"16px"}
        bgcolor={"white"}
        width={"100vw"}
        maxWidth={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <IconButton onClick={() => navigate('/landing')} style={{ padding: 0 }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography fontSize={"16px"} fontWeight={500}>
          {t("Edit Item")}
        </Typography>
        <Box width={"30px"}></Box>
      </Box>

      <Typography
        textAlign={"left"}
        fontSize={"14px"}
        fontWeight={400}
        p={"16px"}
      >
        {t("Please enter all the required(*) information")}
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap" }} px={"16px"} mt={2}>
        <Typography fontWeight={600}>{t("Basic Details")}</Typography>
        <QRCodeScanner
          id="isbn_input_qr"
          label={t("ISBN")}
          value={itemData.isbn || ""}
          onChange={(value) => {
            console.log(value);
            handleChange("isbn", value);
            // fetchByISBN(value);
          }}
          required={false}
          qrEnabled={qrEnabled}
          setQREnabled={setQREnabled}
        />

        <Typography sx={{ mt: 2 }} fontWeight={600}>
          {t("Item Details")}
        </Typography>

        <FormControl sx={{ my: 1, mt: 2 }} fullWidth>
          <Typography sx={{ mt: 1 }} fontWeight={400}>
            {t("Item Type")} *
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ justifyContent: 'space-between' }}
            onChange={(e) => handleChange("item_type", e.target.value)}
            value={itemData.item_type || ""}
          >
            <FormControlLabel id="item_type" value="BOOK" control={<Radio />} label={t("Book")} />
            <FormControlLabel value="MANUSCRIPT" control={<Radio />} label={t("Manuscript")} />
            <FormControlLabel value="WOODBLOCK" control={<Radio />} label={t("Woodblock")} />
            <FormControlLabel value="MODERN_PUBLICATION" control={<Radio />} label={t("Modern Publication")} />
          </RadioGroup>
        </FormControl>
        <FormControl
          sx={{ my: 1, mt: 2, borderRadius: "12px" }}
          variant="outlined"
          fullWidth
          required
        >
          <Autocomplete
            freeSolo
            id="title"
            disableClearable
            sx={{ borderRadius: "12px" }}
            onChange={(_event, _newValue, _reason, details) => {
              const selectedInstance = details?.option;
              if (selectedInstance?.bdrc_id) {
                // setItemData({ ...itemData, title: selectedTitle.id });
                console.log(selectedInstance);
                // ts-ignore
                setItemData(selectedInstance);
                setImageEncodedString(selectedInstance);
              }
            }}
            options={instanceOptions}
            getOptionLabel={(option) =>
              (option as ItemData).title || option.toString()
            }
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  width={'90px'}
                  height={'150px'}
                  // @ts-ignore
                  src={(option as ItemData).front_cover}
                />
                <Typography>{(option as ItemData).title}</Typography>
              </Box>
            )}
            onInputChange={(_event, newInputValue) => {
              setItemData({ ...itemData, title: newInputValue });
              // if (newInputValue.length > 2) debouncedFetchTitles(newInputValue);
            }}
            value={itemData.title || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ background: "white", borderRadius: "12px" }}
                label={`${t("Title")} *`}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderRadius: "12px" },
                  onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                      // debouncedFetchTitles(itemData.title || '');
                    }
                  },
                }}
              />
            )}

          />
        </FormControl>
        <FormControl
          sx={{ my: 1, mt: 2, borderRadius: "12px" }}
          variant="outlined"
          fullWidth
        >
          <Autocomplete
            freeSolo
            id="author-search"
            disableClearable
            sx={{ borderRadius: "12px" }}
            onChange={(_event, _newValue, _reason, details) => {
              const selectedAuthor = details?.option;
              if (selectedAuthor?.id) {
                setItemData({ ...itemData, author: selectedAuthor.id, author_name: selectedAuthor.name });
              }
            }}
            options={authorOptions}
            getOptionLabel={(option) =>
              (option as AuthorOptions).display_name || option.toString()
            }
            onInputChange={(_event, newInputValue) => {
              if (newInputValue.length === 0) {
                setItemData({ ...itemData, author: "", author_name: "" });
                return;
              }
              setItemData({ ...itemData, author_name: newInputValue });
              if (newInputValue.length > 2) fetchAuthors(newInputValue);
            }}
            value={String(itemData.author_name || itemData.author || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ background: "white", borderRadius: "12px" }}
                label={t("Author")}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderRadius: "12px" },
                }}
              />
            )}

          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Edition")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.edition}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="edition"
            label={t("Edition")}

          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Page count")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.page_count}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="page_count"
            inputMode="numeric"
            label={t("Page count")}

          />
        </FormControl>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flex={1}
          justifyContent={"space-between"}
          my={2}
        >
          <ImageUpload
            image={imageEncodedString.front_cover}
            fullImage={itemData.front_cover_full}
            onImageChange={(e) => handleImageChange(e, "front_cover")}
            onRemoveImage={() => {
              setItemData((prevState) => ({ ...prevState, front_cover: "" }));
              setImageEncodedString((prevState) => ({
                ...prevState,
                front_cover: "",
              }));
            }}
            label={`${t("Front Cover")} *`}
            componentID="front_cover"
          />

          <ImageUpload
            image={imageEncodedString.random_page}
            onImageChange={(e) => handleImageChange(e, "random_page")}
            onRemoveImage={() => {
              setItemData((prevState) => ({ ...prevState, random_page: "" }));
              setImageEncodedString((prevState) => ({
                ...prevState,
                random_page: "",
              }));
            }}
            label={t("Random Page")}
            componentID="random_page"
          />

          <ImageUpload
            image={imageEncodedString.back_cover}
            onImageChange={(e) => handleImageChange(e, "back_cover")}
            onRemoveImage={() => {
              setItemData((prevState) => ({ ...prevState, back_cover: "" }));
              setImageEncodedString((prevState) => ({
                ...prevState,
                back_cover: "",
              }));
            }}
            label={t("Back Cover")}
            componentID="back_cover"
          />
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap" }} mt={2}>
          <Typography sx={{ mt: 2, px: 1 }} fontWeight={600}>
            {t("Volume")}
          </Typography>

          <FormControl sx={{ my: 1, mt: 2, px: 1 }} variant="outlined" fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={itemData.is_multi_volume === "true"}
                  onChange={(e) =>
                    handleChange(
                      "is_multi_volume",
                      e.target.checked ? "true" : "false"
                    )
                  }
                  name="is_multi_volume"
                  color="primary"

                />
              }
              label={t("Is this part of a multi-volume set?")}
            />
          </FormControl>
          {itemData.is_multi_volume === "true" && (
            <>
              <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth required>
                <Autocomplete
                  freeSolo
                  id="collection-search"
                  sx={{ background: "white", borderRadius: '12px' }}
                  disableClearable
                  onChange={(_event, _newValue, _reason, details) => {
                    const selectedCollection = details?.option;
                    if (selectedCollection?.id) {
                      setItemData({
                        ...itemData,
                        collection: selectedCollection.id,
                      });
                    }
                  }}
                  options={collectionOptions}
                  getOptionLabel={(option) =>
                    (option as CollectionOptions).title || option.toString()
                  }
                  onInputChange={(_event, newInputValue) => {
                    setItemData({ ...itemData, collection_name: newInputValue });
                    if (newInputValue.length > 2)
                      searchCollections(newInputValue);
                  }}
                  value={String(itemData.collection_name || "")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ borderRadius: '12px' }}
                      label={t("Search for a collection")}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        style: { borderRadius: "12px" }
                      }}
                    />
                  )}

                />
              </FormControl>

              <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
                <InputLabel
                  sx={{ fontWeight: 400, color: "#949494" }}
                  htmlFor="outlined-adornment-password"
                >
                  {t("Volume Number")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  sx={{ background: "white", borderRadius: "12px" }}
                  value={itemData.volume_number}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="volume_number"
                  label={t("Volume Number")}

                />
              </FormControl>
            </>
          )}
        </Box>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Publisher")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.publisher}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="publisher"
            label={t("Publisher")}

          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Year")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.published_year}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="published_year"
            label={t("Year")}

          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Place of publication")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.place_of_publication}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="place_of_publication"
            label={t("Place of publication")}

          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Additional comment")}
          </InputLabel>
          <OutlinedInput
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.notes}
            name="notes"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            label={t("Additional comment")}

          />
        </FormControl>
        <FormControl sx={{ my: 1, flexDirection: 'row', alignItems: 'center' }} variant="outlined" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={itemData.restricted_access == true}
                onChange={(e) => handleChange("restricted_access", e.target.checked)}
                name="restricted_access"
                color="primary"

              />
            }
            label={t("Is this a restricted item?")}
          />
          <Tooltip title="Please mark this box if the item should not be openly accessible to all library members" arrow>
            <InfoOutlinedIcon fontSize='small' sx={{ color: '#3183FF' }} />
          </Tooltip>
        </FormControl>
        <FormControl sx={{ mb: 1, flexDirection: 'row', alignItems: 'center' }} variant="outlined" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={itemData.review}
                onChange={(e) => handleChange("review", e.target.checked)}
                name="review"
                color="primary"

              />
            }
            label={t("Please review item entry")}
          />
          <Tooltip title="Please mark this box if you need clarification on the details entered for this particular item, and would like DoRC to review them." arrow>
            <InfoOutlinedIcon fontSize='small' sx={{ color: '#3183FF' }} />
          </Tooltip>
        </FormControl>
        <FormControl sx={{ mt: 2, mb: 4 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-status"
          >
            {t("Scanning Status")}
          </InputLabel>
          <Select
            labelId="scanning_status_select"
            id="scanning_status_select"
            value={itemData.scanning_status}
            sx={{ background: "white", borderRadius: "12px" }}
            label="Scanning Status"
            name="scanning_status"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value={'DO_NOT_SCAN'}>{t('Do not scan')}</MenuItem>
            <MenuItem value={'CANNOT_SCAN'}>{t('Cannot scan')}</MenuItem>
            <MenuItem value={'TO_BE_SCANNED'}>{t('To be scanned')}</MenuItem>
            <MenuItem value={'SCANNING_IN_PROGRESS'}>{t('Scanning in progress')}</MenuItem>
            <MenuItem value={'ALREADY_SCANNED'}>{t('Already scanned')}</MenuItem>
            <MenuItem value={'TO_BE_RE_SCANNED'}>{t('To be re-scanned')}</MenuItem>
          </Select>
        </FormControl>

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} sx={{ zIndex: 99999 }}>
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            <Typography color={'white'}>
              {errorMessage}
            </Typography>
          </Alert>
        </Snackbar>
      </Box>

      <Box margin={1.5}>
         <Button
          variant="contained"
          onClick={isSaveDisabled() ? handleFocus : handleSubmit }
          disabled={isSaveLoading}
          sx={{
            textTransform: "none",
            py: 2,
            borderRadius: 150,
            width: "100%",
          }}
        >
         {isSaveLoading ? <CircularProgress size={"24px"} sx={{color:"white" }} /> : 
         <Typography
            fontSize={16}
            fontWeight={400}
            color="white"
            letterSpacing={"0.96px"}
          >
            {t("Save")}
          </Typography>} 
        </Button>
      </Box>
    </Container>
  );
}
