import React from 'react';
import { Box, Button } from '@mui/material';
import { Instance,LibraryNameInstance } from '../types';
import styled from 'styled-components';
import { t } from 'i18next';

interface LoadMoreButtonProps {
  items: (LibraryNameInstance | Instance)[]; 
  totalBookCount: number;
  totalInstanceCount: number;
  loading: boolean;
  onLoadMore: () => void;
  search: string;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ items, totalInstanceCount, loading, onLoadMore }) => (
  items.length < totalInstanceCount && !loading && (
    <StyledBox>
      <Button onClick={onLoadMore} disabled={loading}>
        {loading ? `${t('Loading')}...` : t('Load More')}
      </Button>
    </StyledBox>
  )
);

export default LoadMoreButton;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 16px 0;
`;