import { Container, Box, Typography, Button, IconButton, Stack } from '@mui/material'
import React from 'react'
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function LanguageSettings() {
    const { t, i18n } = useTranslation()
    const [lang, setLang] = React.useState<string>()
    const navigate = useNavigate();

    const handleClick = (language: string ) => {
      setLang(language)
    };

    const handleConfirm = () => {
        localStorage.setItem('lang', lang)
        i18n.changeLanguage(lang)
        navigate(-1)
    }
    
  return (
    <Container disableGutters sx={{height: '100vh', overflowY: 'scroll', pb: 8}}>
      {/* Header Box */}
      <Box display={'flex'} flexDirection={'row'} p={'16px'} bgcolor={'white'} width={'100vw'} maxWidth={'100%'} justifyContent={'space-between'} alignItems={'center'}>
            <IconButton onClick={() => navigate(-1)}>
                <KeyboardArrowLeft />
            </IconButton>
          <Typography fontSize={'16px'} fontWeight={500}>
              {t('Language Settings')}
          </Typography>
          <Box width={'30px'}></Box>
      </Box>

        <Typography variant='h5' component='h4' fontSize={22} fontWeight={400} mt={2} px={'2rem'} fontFamily={'sans-serif'} textAlign={'left'}>
            ང་ཚོས་ཁྱེད་ཀྱིས་གདམ་ག་བྱས་པའི་སྐད་ཆ་བདམས་པ་ཡིན།
        </Typography>

        <Typography variant='h5' component='h4' fontSize={16} fontWeight={400} mt={1} px={'2rem'} py={2} textAlign={'left'} textTransform={'full-width'}>
            Please select the language of your choice.
        </Typography>

        <Stack spacing={1} direction="row" justifyContent='space-between' mt={3} px={'2rem'} mb={6}>
            <Button onClick={() => handleClick('bo')} color={lang == 'bo' ? "primary" : "secondary"} variant="outlined" sx={{alignItems: 'center', textTransform: 'none', borderRadius: "8px", width: '154px'}}>
                <Typography fontFamily={'sans-serif'} fontSize={'16px'} fontWeight={600} my={2} color={lang=='bo' ? "primary" : "secondary"}>
                    བོད་སྐད།
                </Typography>
            </Button>
            <Button onClick={() => handleClick('en')} color={lang == 'en' ? "primary" : "secondary"} variant="outlined" sx={{alignItems: 'center', textTransform: 'none', borderRadius: "8px", width: '154px'}}>
                <Typography fontSize={'16px'} fontWeight={600} my={2} color={lang=='en' ? "primary" : "secondary"}>
                    English
                </Typography>
            </Button>
        </Stack>

        <Box position={'absolute'} bottom={'5vh'} left={'50%'} sx={{transform: 'translateX(-50%)', width: '100%'}} px={'16px'}>
            <Button onClick={handleConfirm} variant="contained" disabled={lang == 'en' || lang == 'bo' ? false : true} sx={{textTransform: 'none', py: 2, borderRadius: 150, width: '100%'}}>
                <Typography fontSize={16} fontWeight={400} color='white' letterSpacing={'0.96px'}>
                    {lang == 'en' ? 'Confirm' : 'གཏན་འཁེལ།'}
                </Typography>
            </Button>
        </Box>  
        
      </Container>
  )
}