import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";

// Define the prop types for the AutoCompleteDropdown component
interface AutoCompleteDropdownProps {
  menuList?: string[]; // Array of strings
  selectedOptions: string | null; // Selected option as string
  setSelectedOptions: (option: string | null) => void; // Function to set selected option
  disable?: boolean;
  label?: string;
  required?: boolean;
  loading?: boolean;
  error?: boolean;
  errorText?: string;
}

// Styled Autocomplete component
const CustomAutocomplete = styled(Autocomplete)<{ 
  options: string[]; // Explicitly define the type for options
}>({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "white",
  },
});

const AutoCompleteDropdown: React.FC<AutoCompleteDropdownProps> = ({
  menuList = [],
  selectedOptions = null,
  setSelectedOptions = () => {},
  disable = false,
  label = "",
  required = false,
  loading = false,
  error = false,
  errorText = "",
}) => {
    

  return (
    <CustomAutocomplete
        onChange={(event: any, newValue: string | null) => {
            setSelectedOptions(newValue);
        }}
      isOptionEqualToValue={(option, value) => option === value} 
      value={selectedOptions}
      options={menuList}
      disabled={disable}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          helperText={errorText}
        />
      )}
      noOptionsText={loading ? "Loading..." : "No options found"}
      sx={{
        ".MuiOutlinedInput-root": {
          marginBottom: 1,
          borderRadius: "12px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px",
            borderColor: (theme) => (error ? theme.palette.error.main : ""),
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px",
          },
          backgroundColor: "#FFFFFF",
        },
      }}
    />
  );
};

export default AutoCompleteDropdown;
