import { createTheme } from "@mui/material/styles";
import '@fontsource-variable/noto-sans';


const theme = createTheme({
  palette: {
    primary: {
      main: "#6D2312",
    },
    secondary: {
      main: "#242424",
    },
    error: {
      main: "#F95656",
    },
    background: {
      default: "#FCFAF8",
    },
    action: {
      disabledBackground: "#6D2312CC",
    },
    info: {
      main: "#949494",
    },
  },
  typography: {
    fontFamily: "Noto Sans Variable",
    allVariants: {
      color: "#242424",
    },
  }
});

export default theme;
