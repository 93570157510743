import { useEffect, useState } from 'react';
import { useQuery,useQueryClient } from '@tanstack/react-query';
import { getLibraryInstances,getAllLibraryInstances } from '../api/items';
import { Instance, LibraryNameInstance } from '../types';
import { getLibraryBookCount } from '../api/library';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { LibraryType } from '../utils/enum';

export const useItems = (search: string, offset: number,libraryType:LibraryType, itemType?: string) => {
  const [items, setItems] = useState<(LibraryNameInstance| Instance )[]>([]);
  const [totalBookCount, setTotalBookCount] = useState<number>(0);
  const [totalInstanceCount, setTotalInstanceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const authenticatedApiHook = useAuthenticatedRequest();
  const queryClient = useQueryClient();
  const {
    data: response,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['fetchInstances', search, offset, itemType,libraryType],
    queryFn: () => {
      if (libraryType === LibraryType.MY_LIBRARY) {
        return getLibraryInstances(
          authenticatedApiHook,
          search,
          offset,
          false,
          'recent',
          undefined,
          false,
          itemType,
        );
      }
      return null; 
    },
    staleTime: 60 * 1000,
  });

  const {
    data: allLibraryResponse,
    isFetching:isFetchingAllLibrary,
    error:errorAllLibrary,
  } = useQuery({
    queryKey: ['fetchAllInstances', search, offset, itemType,libraryType],
    queryFn: () => {
      if (libraryType === LibraryType.ALL_LIBRARIES) {
        return getAllLibraryInstances(
          authenticatedApiHook,
          search,
          offset,
          'recent',
          itemType,
        );
      } 
      return null;
    },
    staleTime: 60 * 1000,
  });


  // Query to fetch library book count
  const {
    data: bookCountDetails,
    isFetching: bookCountFetching,
    error: bookCountError,
  } = useQuery({
    queryKey: ['fetchBookCount', search, itemType],
    queryFn: () => {
      if (libraryType === LibraryType.MY_LIBRARY) {
        return getLibraryBookCount(authenticatedApiHook, itemType, search, false, false);
      }
      return null; 
    },
    enabled: libraryType === LibraryType.MY_LIBRARY,
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['fetchInstances'] });
    queryClient.invalidateQueries({ queryKey: ['fetchBookCount'] });
    queryClient.invalidateQueries({ queryKey: ['fetchAllInstances'] });
  }, [search, queryClient,libraryType]);


useEffect(() => {
  if (response) {
    setLoading(true);

    const noAppend = (search && offset === 0) || (libraryType !== undefined && offset === 0);

    if (libraryType === LibraryType.MY_LIBRARY) {
      const newItems: Instance[] = response.instances; 
      
      if (noAppend) {
        setItems(newItems);
      } else {
        setItems((prevItems) => [...prevItems, ...newItems]);
      }
    }
    
    setTotalInstanceCount(response.count || 0);
  }
}, [response, search, offset, libraryType]);

useEffect(() => {
  if (allLibraryResponse) {
    setLoading(true);

    const noAppend = (search && offset === 0) || (libraryType !== undefined && offset === 0);

    if (libraryType === LibraryType.ALL_LIBRARIES) {
      const newItems: LibraryNameInstance[] = allLibraryResponse.instances;
      
      if (noAppend) {
        setItems(newItems);
      } else {
        setItems((prevItems) => [...prevItems, ...newItems]);
      }
    }
    
    setTotalInstanceCount(allLibraryResponse.count || 0);
  }
}, [allLibraryResponse, search, offset, libraryType]);

  useEffect(() => {
    if (bookCountDetails) {
      setTotalBookCount(bookCountDetails.count || 0);
    }
  }, [bookCountDetails]);

  useEffect(() => {
    setLoading(bookCountFetching || isFetching || isFetchingAllLibrary);
  }, [isFetching, bookCountFetching,isFetchingAllLibrary]);

  if (error || bookCountError || errorAllLibrary) {
    console.error('Error loading items:', error || bookCountError || errorAllLibrary);
  }

  return { items, totalBookCount, loading, totalInstanceCount };
};
