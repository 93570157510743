import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Modal, OutlinedInput, Typography, Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Instance } from '../types';
import {
    getTitles,
    getItemByISBN,
} from "../api/items";
import { useTranslation } from "react-i18next";
import { debounce } from "../utils/func";
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';

interface InstanceSelectorProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    setSelection:  <T>(data: T) => void;
    searchType: 'isbn' | 'title';
    initialInput: string;
}

const InstanceSelector: React.FC<InstanceSelectorProps> = ({ open, setOpen, setSelection, searchType, initialInput }) => {
    const { t } = useTranslation();
    const [instanceList, setInstanceList] = useState<Instance[]>([]);
    const [currentSelection, setCurrentSelection] = useState<number | null>(null);
    const [searchInput, setSearchInput] = useState<string>(initialInput);
    const [loading, setLoading] = useState<boolean>(false);
    const api = useAuthenticatedRequest();
    const queryClient = useQueryClient();

    const fetchInstances = async (query: string, key: string, getterFunction: any) => {
        try {
            const data = await queryClient.fetchQuery({
                queryKey: [key, query],
                queryFn: async () => getterFunction(api, query),
                staleTime: 60 * 1000
            });
            setInstanceList(data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setSearchInput(initialInput);
    }, [initialInput, open]);

    useEffect(() => {
        if (searchType === 'isbn' && searchInput) {
            debouncedISBNSearch(searchInput);
        } else if (searchType === 'title' && searchInput) {
            setLoading(true);
            debouncedTitleSearch(searchInput);
        }
    }, [searchInput, searchType]);

    const debouncedISBNSearch = useCallback(debounce((v) => {
      if (v.length === 13 || v.length === 10) {
        setLoading(true);
        fetchInstances(v, 'isbn', getItemByISBN)
      }
    }, 1000), []);
    const debouncedTitleSearch = useCallback(debounce((v) => fetchInstances(v, 'title', getTitles), 1000), []);

    return (
        <Modal
            open={open}
            onClose={setOpen}
            closeAfterTransition
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 2 }}
        >
            <Box>
                <Box sx={{ bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography variant="h6">{t("Search for a title or ISBN")}</Typography>
                    <OutlinedInput
                        placeholder={t("Search for a title or ISBN")}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                </Box>

                <Box sx={{ bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography variant="h6">{t("Select an instance")}</Typography>
                    
                    <Box sx={{ height: '400px', overflowY: 'auto' }}>
                      {loading && <Typography>{t("Loading")}...</Typography>}
                      {!loading && !instanceList && <Typography>{t("No matching instances")}</Typography>}
                      {instanceList && instanceList.map((instance, idx) => (
                        <Box
                          key={idx}
                          onClick={() => setCurrentSelection(idx)}
                          sx={{
                            p: 2,
                            mt: 2,
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            cursor: 'pointer',
                            backgroundColor: currentSelection === idx ? '#f0f0f0' : 'transparent',
                          }}
                        >
                          <Grid container>
                            <Grid item xs={6}>
                              <img
                                src={instance.front_cover}
                                alt={instance.title}
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{instance.title}</Typography>
                              <Typography>{instance.author}</Typography>
                              <Typography>{instance.edition}</Typography>
                              <Typography>{instance.published_year}</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
                        {currentSelection !== null && <Button
                            onClick={() => {
                                setSelection(instanceList[currentSelection!]);
                                setOpen(false);
                            }}
                            sx={{ mt: 2 }}
                            variant="contained"
                        >
                            {t("Select")}
                        </Button>}
                        <Button
                            onClick={() => {
                                setInstanceList([]);
                                setOpen(false);
                            }}
                            sx={{ mt: 2, ml: 2 }}
                            variant="outlined"
                        >
                            {t("Enter Manually")}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default InstanceSelector;
