import * as React from 'react';
import { Box, Typography, Avatar,Chip,IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface UserProps {
    userName: string;
    cataloguedItems: number;
    userId?: string
}
  

const AssociatedLibrarian:  React.FC<UserProps> =  ({userId, userName, cataloguedItems }) => {
    // Handle cases where props are not provided
    const name = userName || 'Unknown User';
    const navigate = useNavigate();
    const itemsCount = cataloguedItems || 0;

    return (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Avatar sx={{ bgcolor: '#F4F1E7', color: '#908056', fontFamily: 'serif' }}> {/* Avatar might change accordingly */}
                {name.slice(0, 2).toUpperCase()}
                </Avatar>
                <Typography px={'12px'} fontSize={'14px'}>
                {name}
                </Typography>
                <Chip label={String(itemsCount)} sx={{height: '24px', mx: 2, backgroundColor: '#D1DBE9', color: '#1458BD'}}/>
            </Box>
            <IconButton onClick={() => navigate(`/my-items?user_id=${userId}`)} sx={{paddingRight: 0}}>
                <ChevronRightIcon />
            </IconButton>
        </Box>
    );
};
  

export default AssociatedLibrarian;